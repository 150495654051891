import { useEffect, useRef, useState } from 'react';
import Image from 'next/future/image';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import { ChevronLeft, ChevronRight } from '@/icons';
import { Prose } from '@/molecules';

import { useMatchMedia } from '@/lib/hooks';

import type { Splide as SplideType } from '@splidejs/splide';
import type { ReactNode, RefObject } from 'react';
import type { TypeTimeLineProps } from './types';

const TimeLineCard = ({ card }: { card: TypeTimeLineProps }) => (
  <div
    className="flex h-full flex-col gap-3 p-4 min-[450px]:w-64"
    style={{
      backgroundColor: card.cardColor,
    }}
  >
    <h3
      className="u-headline u-headline--h2 text-center"
      style={{
        color: card.titleColor,
      }}
    >
      {card.title}
    </h3>

    <Prose
      className="u-body u-body--s grow text-center leading-tight"
      html={card.description}
    />

    <div className="u-aspect-square relative">
      <Image
        src={card.image.sourceUrl}
        alt={card.image.altText}
        fill
        className="object-cover object-center"
      />
    </div>
  </div>
);

const TimeLineController = ({
  children,
  splideRef,
}: {
  splideRef: RefObject<Splide>;
  children: ReactNode;
}) => {
  const [controllerState, setControllerState] = useState({
    disabledPrev: true,
    disabledNext: false,
  });

  const updateControllers = (splide: SplideType) => {
    setControllerState({
      disabledNext:
        splide?.Components?.Controller.getIndex() ===
        splide?.Components?.Controller.getEnd(),
      disabledPrev: splide?.Components?.Controller.getIndex() === 0,
    });
  };

  useEffect(() => {
    if (!splideRef.current) return;

    splideRef.current.splide?.event.on('move', function () {
      if (!splideRef.current?.splide) return;

      updateControllers(splideRef.current.splide);
    });
  }, [splideRef]);

  return (
    <div className="flex flex-row items-center justify-center gap-1">
      <button
        className="group shrink-0"
        onClick={() => {
          if (!splideRef.current?.splide) return;
          splideRef.current.splide.Components.Controller.go('<');
          updateControllers(splideRef.current.splide);
        }}
        disabled={controllerState.disabledPrev}
        aria-label="Slide Anterior"
      >
        <ChevronLeft className="h-10 w-10 group-disabled:opacity-20 min-[450px]:h-12 min-[450px]:w-12" />
      </button>

      {children}

      <button
        className="group shrink-0"
        onClick={() => {
          if (!splideRef.current?.splide) return;
          splideRef.current.splide.Components.Controller.go('>');
          updateControllers(splideRef.current.splide);
        }}
        disabled={controllerState.disabledNext}
        aria-label="Slide Siguiente"
      >
        <ChevronRight className="h-10 w-10 group-disabled:opacity-20 min-[450px]:h-12 min-[450px]:w-12" />
      </button>
    </div>
  );
};

const TimeLineStrip = ({
  cards,
  splideRef,
}: {
  cards: TypeTimeLineProps[];
  splideRef: RefObject<Splide>;
}) => {
  const { matches } = useMatchMedia({
    query: '(max-width: 450px)',
  });

  return (
    <>
      {/**
       * TODO: La versión que tenemos de Splide no tiene el prop Children ya que en React 17 se añadia automaticamente pero en la 18 no
       * Cuando actualicemos Splide a la última versión, podemos quitar el @ts-ignore
       * @eslint-disable-next-line @typescript-eslint/ban-ts-comment
       * @ts-expect-error types are not for react 18 */}
      <Splide
        onMounted={(splide) => splideRef?.current?.splide?.sync(splide)}
        onUpdated={(splide) => splide?.refresh()}
        options={{
          autoWidth: true,
          pagination: false,
          arrows: false,
          gap: '1rem',
          trimSpace: !matches,
          drag: false,
          focus: 'center',
        }}
        className="-mx-4 mt-5 flex justify-center overflow-hidden min-[450px]:mx-[52px] [&_li.splide\_\_slide:first-child>div>div]:rounded-l-full [&_li.splide\_\_slide:last-child>div>div]:rounded-r-full"
      >
        {cards.map((card, i) => (
          <SplideSlide key={i}>
            <div className="flex w-64 flex-col gap-4 max-[450px]:w-[calc(100vw_-_120px)]">
              <div className="h-2 bg-[#94897a]" />
              <p className="u-headline u-headline--h2 text-center">
                {card?.timelineTitle ?? card.title}
              </p>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </>
  );
};

export const TimeLine = ({
  title,
  cards,
}: {
  title: string;
  cards: TypeTimeLineProps[];
}) => {
  const splideRef = useRef<Splide>(null);

  const { matches } = useMatchMedia({
    query: '(max-width: 450px)',
  });

  return (
    <>
      {title && (
        <h2 className="u-headline u-headline--h2 mb-2 text-typo-primary">
          {title}
        </h2>
      )}
      <TimeLineController splideRef={splideRef}>
        <div className="flex flex-col overflow-auto">
          {/**
           * TODO: La versión que tenemos de Splide no tiene el prop Children ya que en React 17 se añadia automaticamente pero en la 18 no
           * Cuando actualicemos Splide a la última versión, podemos quitar el @ts-ignore
           * @eslint-disable-next-line @typescript-eslint/ban-ts-comment
           * @ts-expect-error types are not for react 18 */}
          <Splide
            onUpdated={(splide) => {
              splide.refresh();
            }}
            ref={splideRef}
            options={{
              ...(matches
                ? {
                    perPage: 1,
                    perMove: 1,
                    autoWidth: false,
                  }
                : {
                    autoWidth: true,
                    focus: 'center',
                  }),
              gap: '1rem',
              pagination: false,
              type: 'slide',
              updateOnMove: true,
              arrows: false,
              drag: true,
              width: 1160,
            }}
          >
            {cards.map((card, i) => (
              <SplideSlide
                key={i}
                className="min-[450px]:[&:not(.is-active)]:opacity-50"
              >
                <TimeLineCard card={card} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </TimeLineController>
      <TimeLineStrip cards={cards} splideRef={splideRef} />
    </>
  );
};
